import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/dictionary/prospect_origin_service.js";
const _M_GET_ALL = "getAllProspectOrigin";
const _M_GET_BY_ID = "getProspectOriginById";

/** Service d'accès à l'API des origines de prospection */
export default class ProspectOriginService {
  constructor(apis) {
    this.apis = apis;
    this.api = this.apis.getOriginProspectApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////
  /**
   * Récupère la liste des origines de prospection et les tri par ordre alphabétique
   */
  async getAllProspectOrigin() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération de toutes les origines de prospection."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère une origines de prospection par son id
   */
  async getProspectOriginById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_BY_ID,
      "Récupération d'une origine de prospection avec l'id : " + id
    );

    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  async create(item) {
    return this.api.create(item).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(item.id);
      converter.add409AlreadyExists(item.label);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  async update(item) {
    return this.api.update(item).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(item.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  async delete(itemId) {
    return this.api.delete(itemId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(itemId);
      converter.convert(error);
    });
  }
}
